import { deleteRequest, get, patch, post } from "../Utils";

export const addBlog = async (data) => {
  return post(`/admin/blog`, data);
};

export const getBlog = async (data) => {
  const page = data.queryKey?.[1];
  const pagination = {
    page: page,
    limit: 8,
  };
  return get(`/admin/blog`, pagination);
};

export const getSingleBlog = async (data) => {
  const blogId = data.queryKey?.[1];
  return get(`/admin/blog/${blogId}`);
};

export const deleteBlog = async (id) => {
  return deleteRequest(`/admin/blog/${id}`);
};

export const updateBlog = async (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/blog/${id}`, data);
};

export const apiBlog = {
  addBlog,
  getBlog,
  deleteBlog,
  updateBlog,
  getSingleBlog,
};
