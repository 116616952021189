import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import deleteIcon from "./../assets/images/delete.png";
import { Avatar } from "@mui/material";
import { useDeleteBlog } from "../Hooks/Blog";
import { useDeleteCategory } from "../Hooks/Category";
import { useDeleteSubCategory } from "../Hooks/SubCategory";

const DeleteModel = ({ open, onClose, mainTitle, id }) => {
  const { mutate: deleteBlog } = useDeleteBlog();
  const { mutate: deleteCategory } = useDeleteCategory();
  const { mutate: deleteSubCategory } = useDeleteSubCategory();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 415,
    background: "#ffffff",
    boxShadow: 24,
    p: 2,
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              alt="Remy Sharp"
              src={deleteIcon}
              sx={{ width: 50, height: 50 }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              Are You Sure?
            </Typography>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{
              fontSize: "15px",
              p: 1,
              textAlign: "center",
            }}
          >
            Are you sure you want to Detele {mainTitle}?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                mt: 1,
                width: "200px",
              }}
            >
              <Button
                className="delete-button"
                color="error"
                onClick={() => {
                  if (mainTitle === "Category") {
                    deleteCategory(id);
                  } else if (mainTitle === "Sub Category") {
                    deleteSubCategory(id);
                  } else {
                    deleteBlog(id);
                  }
                  onClose();
                }}
                sx={{
                  background: "#ffe6e6",
                  padding: "7px 20px 7px 20px ",
                  textTransform: "capitalize",
                }}
              >
                Delete
              </Button>
              <Button
                onClick={onClose}
                className="delete-button"
                size="sm"
                sx={{
                  background: "#e6ffe6",
                  padding: "7px 20px 7px 20px ",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModel;
