import { Box, Button, InputBase, styled, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import * as Yup from "yup";
import {
  useAddSubCategory,
  useGetSubCategory,
  useUpdateSubCategory,
} from "../../Hooks/SubCategory";
import _ from "lodash";

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("name is Required"),
});

const AddSubCategory = () => {
  const BoxWrapper = styled(Box)(() => ({
    backgroundColor: "#F8FAFB",
    height: "79vh",
    padding: "15px",
    borderTop: "2px solid #E6EAEF",
    ".subBox": {
      background: "#E2E8F0",
      padding: "50px",
      borderRadius: "20px",
      height: "78vh",
      border: '1px solid #d9d9d9'
    },
    ".breadcrumbs-box": {
      display: "flex",
      alignItems: "center",
    },
    ".back-btn": {
      color: "#000",
      marginRight: "10px",
    },
    '.breadCrumbs': {
      fontSize: "12px",
      fontWeight: 300,
      textAlign: "left",
      width: "auto",
      color: '#1A202C'
    },
    '.subCategory': {
      color: "#039CFD"
    },
    '.mainHeading': {
      fontSize: "25px",
      fontWeight: 400,
      padding: "30px 0px 10px 0px",
      textAlign: "left",
    },
    '.filedTitle': {
      fontSize: "16px",
      fontWeight: 500,
      padding: "10px 0px",
      textAlign: "left",
      color: '#1A202C'
    },
    '.input': {
      backgroundColor: "none",
      paddingLeft: "15px",
      borderRadius: "8px",
      height: "60px",
      width: "100%",
      background: "#f0f0f1",
    },
    '.errorMesage': {
      color: "red",
      fontSize: "12px"
    },
    '.subCategroyBox': {
      padding: "12px 0px"
    },
    '.loginBtn': {
      height: 55,
      fontSize: 16,
      fontWeight: 500,
      background: "#1A202C",
      textTransform: "capitalize",
      color: "#FFFFFF",
      borderRadius: "8px",
      width: "100%",
      marginTop: "2% ",
    },
    '.loginBtn:hover': {
      background: "#1A202C",
    }

  }));

  const { id } = useParams();
  const { mutate: addSubCategory } = useAddSubCategory();
  const { mutate: test } = useUpdateSubCategory();
  const { data: getData } = useGetSubCategory();

  const newAddSubcategory = getData?.data?.subCategory?.results;
  const finalData = newAddSubcategory?.find((data) => data.id === id);
  return (
    <BoxWrapper>
      <Box className="subBox">
        <Box className="breadcrumbs-box">
          <Link to="/sub-category-list">
            <ArrowBackOutlinedIcon className="back-btn" />
          </Link>
          <Typography className="breadCrumbs">
            Dashboard /
            {id ? (
              <span className="subCategory" >edit Sub Category</span>
            ) : (
              <span className="subCategory">Add Sub Category</span>
            )}
          </Typography>
        </Box>
        {id ? (
          <Typography className="mainHeading">Edit SUB CATEGORY :-</Typography>
        ) : (
          <Typography className="mainHeading">ADD SUB CATEGORY :-</Typography>
        )}

        <Formik
          initialValues={{
            name: _.get(finalData, "name", ""),
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting }) => {
            const addValues = {
              ...values,
              id,
              category: "63e9d94a8f1026ae4a2456dd",
            };
            const addValuesss = {
              ...values,
              category: "63e9d94a8f1026ae4a2456dd",
            };
            id ? test(addValues) : addSubCategory(addValuesss);
          }}
        >
          {({ errors, touched, handleSubmit, values, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <Box>
                {id ? (
                  <Typography className="filedTitle">Edit Category Name :-</Typography>
                ) : (
                  <Typography className="filedTitle">Sub Category Name :-</Typography>
                )}

                <InputBase
                  placeholder="Enter Sub Category Name"
                  fullWidth
                  onChange={handleChange}
                  name="name"
                  value={values.name}
                  className='input'
                />
                {touched.name && errors.name && (
                  <Typography className="errorMesage" >
                    {errors.name}
                  </Typography>
                )}
              </Box>

              <Box className='subCategroyBox'>
                {id ? (
                  <Button className="loginBtn" variant="contained" type="submit" fullWidth>
                    Edit Sub Category
                  </Button>
                ) : (
                  <Button className="loginBtn" variant="contained" type="submit" fullWidth>
                    Add Sub Category
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </BoxWrapper>
  );
};

export default AddSubCategory;
