import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useGetCategory } from "../../Hooks/Category";
import { Link, useNavigate } from "react-router-dom";
import DeleteModel from "../DeleteModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFB",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  //! hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CategoryList = () => {
  const navigate = useNavigate();
  const { data: getAllCategory } = useGetCategory();
  const categoryList = getAllCategory?.data?.category?.results;
  const rows = categoryList?.map((data) => {
    return {
      name: data?.name,
      id: data?.id,
    };
  });

  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState("");
  const handleClose = () => {
    setShowModel(false);
  };
  const handleOpen = () => {
    setShowModel(true);
  };

  const BoxWrapper = styled(Box)(() => ({
    backgroundColor: "#F8FAFB",
    height: "79vh",
    padding: "15px",
    borderTop: "2px solid #E6EAEF",
    '.subBox': {
      background: "#f6f7f9",
      padding: "50px",
      borderRadius: "20px",
      height: "70vh",
    },
    ".add-category-btn": {
      textDecoration: "none",
    },
    '.add-category': {
      backgroundColor: "#1A202C",
    },
    '.breadCrumbs': {
      fontSize: "12px",
      fontWeight: 300,
      textAlign: "left",
      width: "auto",
    },
    '.mainHeading': {
      fontSize: "25px",
      fontWeight: 400,
      padding: "10px 0px",
      textAlign: "left",
      paddingBottom: "30px",
    },
    '.listTable': {
      minWidth: 700
    },
    '.dashboardBox': {
      display: "flex",
      justifyContent: "space-between"
    },
    '.editIcon': {
      color: "#1565c0",
    },
    '.categoryList': {
      color: "#039CFD"
    }
  }));
  const Breadcrumbs = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: 300,
    textAlign: "left",
    width: "auto",
  }));
  return (
    <BoxWrapper>
      <Box className='subBox'>
        <Box className='dashboardBox'>
          <Box>
            <Typography className="breadCrumbs">
              Dashboard /
              <span className="categoryList" >CategoryList</span>
            </Typography>
            <Typography className="mainHeading">CATEGORY LIST :-</Typography>
          </Box>
          <Link to="/add-category" className="add-category-btn">
            <Button className="add-category" variant="contained">Add Category</Button>
          </Link>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table className="listTable" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">NUMBER</StyledTableCell>
                  <StyledTableCell align="center">
                    CATEGORY NAME
                  </StyledTableCell>
                  <StyledTableCell align="center">ACTION</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      <ModeEditOutlineIcon
                        className="editIcon"
                        onClick={() => navigate(`/edit-category/${row.id}`)}
                      />
                      <DeleteIcon
                        onClick={() => {
                          setShowModel(true);
                          setId(row?.id);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>


      {/* //! deleteModel for category delete: */}
      {showModel && (
        <DeleteModel
          open={handleOpen}
          onClose={handleClose}
          id={id}
          mainTitle="Category"
        />
      )}
    </BoxWrapper>
  );
};

export default CategoryList;
