import { deleteRequest, get, patch, post } from "../Utils/index";

export const addSubCategory = async (data) => {
  return post(`/admin/subcategory`, data);
};

export const getSubCategory = async (data) => {
  return get(`/admin/subcategory`);
};

export const updateSubCategory = async (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/subcategory/${id}`, data);
};

export const deleteSubCategory = async (id) => {
  return deleteRequest(`/admin/subcategory/${id}`);
};

export const subCategoryApi = {
  addSubCategory,
  getSubCategory,
  updateSubCategory,
  deleteSubCategory,
};
