import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { subCategoryApi } from "../api/SubCategory";

export const useAddSubCategory = () => {
  const navigate = useNavigate();
  return useMutation("test1", subCategoryApi.addSubCategory, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      navigate("/sub-category-list");
    },
    onError: (res) => {
      toast.success(res?.data?.msg);
    },
  });
};

export const useGetSubCategory = () => {
  return useQuery("get data", subCategoryApi.getSubCategory, {
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      // toast.success("Get Sub Category success");
    },
    onError: (res) => {
      // toast.error("Get Sub Category error");
    },
  });
};

export const useUpdateSubCategory = () => {
  const navigate = useNavigate();
  return useMutation(subCategoryApi.updateSubCategory, {
    onSuccess: (res) => {
      // toast.success("Update Sub Category");
      toast.success(res?.data?.message);
      navigate("/sub-category-list");
    },
    onError: (res) => {
      toast.error("Update Sub Category error");
    },
  });
};

export const useDeleteSubCategory = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(subCategoryApi.deleteSubCategory, {
    onSuccess: (res) => {
      queryClient.invalidateQueries("get data");
      // toast.success("delete Sub Category Success");
      toast.success(res?.data?.msg);
      navigate("/sub-category-list");
    },
    onError: (res) => {
      toast.error("Delete Sub Category error");
    },
  });
};
