import React from "react";
import {
  Avatar,
  Button,
  Card,
  Grid,
  InputBase,
  styled,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import frontImage from "./../../assets/images/frontImage.png";
import logo from "./../../assets/images/logo.png";
import * as Yup from "yup";
import { useForgetPassword } from "../../Hooks/authHooks";

const SignupSchema = Yup.object().shape({
  email: Yup.string().required("Emailid is Required"),
});

const ForgetPassword = () => {
  const MainContentForm = styled(Box)(() => ({
    width: "380px",
  }));
  const Title = styled(Typography)(() => ({
    fontSize: 28,
    fontWeight: 700,
    color: "#FFFFFF",
    padding: "10px 0px",
  }));
  const FieldTitle = styled(Typography)(() => ({
    fontSize: 14,
    fontWeight: 500,
    padding: "10px 0px",
    color: "white",
  }));
  const LoginButton = styled(Button)(() => ({
    height: 48,
    fontSize: 16,
    fontWeight: 400,
    background: "#039CFD",
    textTransform: "capitalize",
    borderRadius: "8px",
  }));
  const ParentBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "121px 0px",
    backgroundColor: "#171820",
  }));

  const DetailsTop = styled(Typography)(() => ({
    fontSize: 16,
    fontWeight: 500,
    color: "#C6C7C9",
  }));
  const Input = styled(InputBase)(() => ({
    border: "1px solid #575F62 ",
    backgroundColor: "#22232E",
    color: "#AEB8C6",
    paddingLeft: "15px",
    borderRadius: "8px",
    height: "48px",
  }));

  const { mutate: forgetData } = useForgetPassword();

  return (
    <React.Fragment>
      <Card
        sx={{
          display: "flex",
          height: "100vh",
          backgroundColor: "#171820",
          overflow: "visible",
        }}
      >
        <Grid container spacing={0}>
          <Grid
            sx={{
              backgroundImage: `url(${frontImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
            item
            xs={12}
            md={6}
            sm={12}
          ></Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Box display="flex" justifyContent="center" paddingTop={"73px"}>
              <Avatar
                variant="square"
                alt="cost-Optimization"
                src={logo}
                sx={{
                  height: "66px",
                  width: "66px",
                }}
              />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: 20,
                  letterSpacing: "0.1em",
                  fontWeight: "700",
                  color: "white",
                }}
              >
                DatabeanStalk
              </Typography>
            </Box>
            <ParentBox>
              <MainContentForm>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values) => {
                    forgetData(values);
                  }}
                >
                  {({
                    errors,
                    touched,
                    handleSubmit,
                    values,
                    handleChange,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Title>Forgot Your Password</Title>
                      <DetailsTop>
                        Please enter your Databeanstalk registered email address
                        to receive password{" "}
                        <strong style={{ color: "#039CFD" }}>
                          {" "}
                          Reset Information.{" "}
                        </strong>
                      </DetailsTop>
                      <Box>
                        <FieldTitle>Email ID *</FieldTitle>

                        <Input
                          placeholder="your@mail.com"
                          fullWidth
                          onChange={handleChange}
                          name="email"
                        />
                        {touched.email && errors.email && (
                          <Typography sx={{ color: "red", fontSize: "12px" }}>
                            {errors.email}
                          </Typography>
                        )}
                      </Box>
                      <Box sx={{ padding: "30px 0px 20px 0px" }}>
                        <LoginButton
                          variant="contained"
                          type="submit"
                          fullWidth
                        >
                          Submit
                        </LoginButton>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Link
                          to="/"
                          style={{
                            color: "#039CFD",
                            fontSize: 16,
                            fontWeight: 700,
                            paddingLeft: 5,
                            textDecoration: "none",
                            borderRadius: "8px",
                          }}
                        >
                          Back to login
                        </Link>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </MainContentForm>
            </ParentBox>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};
export default ForgetPassword;
