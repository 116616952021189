import { Box } from "@mui/material";
import React from "react";

const Dashboard = () => {
  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      This is a dashboard page
    </Box>
  );
};

export default Dashboard;
