import { Box, Button, InputBase, styled, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import * as Yup from "yup";
import {
  useAddCategory,
  useGetCategory,
  useUpdateCategory,
} from "../../Hooks/Category";
import * as _ from "lodash";

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Category name is Required"),
});

const AddCategory = () => {
  const { id } = useParams();
  const BoxWrapper = styled(Box)(() => ({
    backgroundColor: "#F8FAFB",
    height: "79vh",
    padding: "15px",
    borderTop: "2px solid #E6EAEF",
    '.subBox': {
      background: "#E2E8F0",
      padding: "50px",
      borderRadius: "20px",
      height: "78vh",
      border: '1px solid #d9d9d9'
    },
    ".breadcrumbs-box": {
      display: "flex",
      alignItems: "center",
    },
    ".back-btn": {
      color: "#000",
      marginRight: "10px",
    },
    ".breadcrumbs": {
      fontSize: "12px",
      fontWeight: 300,
      textAlign: "left",
      width: "auto",
      color: '#1A202C'
    },
    '.mainHeading': {
      fontSize: "25px",
      fontWeight: 400,
      padding: "30px 0px 10px 0px",
      textAlign: "left",
      color: '#1A202C'
    },
    '.fieldTitle': {
      fontSize: "16px",
      color: '#1A202C',
      fontWeight: 500,
      padding: "10px 0px",
      textAlign: "left",
    },
    '.loginBtn': {
      height: 55,
      fontSize: 16,
      fontWeight: 500,
      background: "#1A202C",
      textTransform: "capitalize",
      color: "#FFFFFF",
      borderRadius: "8px",
      width: "100%",
      marginTop: "2% ",
    },
    '.loginBtn:hover': {
      background: "#1A202C",
    },
    '.btnBox': {
      padding: "12px 0px"
    },
    '.errorMesage': {
      color: "red", fontSize: "12px"
    },
    '.input': {
      backgroundColor: "none",
      paddingLeft: "15px",
      borderRadius: "8px",
      height: "60px",
      width: "100%",
      background: "#f0f0f1",
    }

  }));
  const { mutate: addCategory } = useAddCategory();
  const { mutate: updateCategory } = useUpdateCategory();
  const { data: newCategory } = useGetCategory(!id);
  const newAddCategory = newCategory?.data?.category?.results;
  const finalData = newAddCategory?.find((data) => data.id === id);
  return (
    <BoxWrapper>
      <Box className="subBox">
        <Box className="breadcrumbs-box">
          <Link to="/category-list">
            <ArrowBackOutlinedIcon className="back-btn" />
          </Link>
          <Typography className="breadcrumbs" >
            Dashboard /
            {id ? (
              <span style={{ color: "#039CFD" }}>Edit Category</span>
            ) : (
              <span style={{ color: "#039CFD" }}>Add Category</span>
            )}
          </Typography>
        </Box>
        {id ? (
          <Typography className="mainHeading">EDIT CATEGORY :-</Typography>
        ) : (
          <Typography className="mainHeading">ADD CATEGORY :-</Typography>
        )}
        <Formik
          initialValues={{
            name: _.get(finalData, "name", ""),
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            id ? updateCategory({ ...values, id }) : addCategory(values);
          }}
        >
          {({ errors, touched, handleSubmit, values, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <Box>
                <Typography className="fieldTitle">Category Name :-</Typography>
                <InputBase
                  placeholder="category name"
                  fullWidth
                  onChange={handleChange}
                  name="name"
                  value={values.name}
                  className='input'
                />
                {touched.name && errors.name && (
                  <Typography className="errorMesage">
                    {errors.name}
                  </Typography>
                )}
              </Box>
              <Box className='btnBox'>
                {id ? (
                  <Button className="loginBtn" variant="contained" type="submit" fullWidth>
                    Edit Category
                  </Button>
                ) : (
                  <Button className="loginBtn" variant="contained" type="submit" fullWidth>
                    Add Category
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </BoxWrapper>
  );
};

export default AddCategory;
