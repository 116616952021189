import {
  Box,
  Button,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import { useGetBlog } from "../../Hooks/Blog";
import DeleteModel from "../DeleteModel";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFB",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BlogList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { data: getBlog } = useGetBlog(page);

  useEffect(() => {
  }, [page]);

  const finalBlog = getBlog?.data?.blog?.results;
  const paginationBlog = getBlog?.data?.blog;

  const rows = finalBlog?.map((data) => {
    return {
      name: data?.title,
      image: data?.image,
      id: data.id,
      description: data.shortDescription,
    };
  });

  //! for deleteBlog modal
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState("");
  const handleClose = () => {
    setShowModel(false);
  };
  const handleOpen = () => {
    setShowModel(true);
  };

  const MainHeading = styled(Typography)(() => ({
    fontSize: "25px",
    fontWeight: 400,
    padding: "10px 0px",
    textAlign: "left",
    paddingBottom: "30px",
  }));
  const MainBox = styled(Box)(() => ({
    backgroundColor: "#F8FAFB",
    padding: "15px",
    borderTop: "2px solid #E6EAEF",
    '.delete-icon': {
      cursor: 'pointer',
    },
    '.edit-icon': {
      cursor: 'pointer',
      color: '#1565c0'
    }

  }));
  const SubBox = styled(Box)(() => ({
    background: "#f6f7f9",
    padding: "50px",
    borderRadius: "20px",
    height: "70vh",
    ".breadcrumbs-box": {
      display: "flex",
      justifyContent: "space-between",
    },
    ".add-category-btn": {
      textDecoration: "none",
      background: '#1A202C',
    },
  }));
  const Breadcrumbs = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: 300,
    textAlign: "left",
    width: "auto",
  }));
  return (
    <MainBox>
      <SubBox>
        <Box className="breadcrumbs-box">
          <Box>
            <Box>
              <Breadcrumbs>
                Dashboard / <span style={{ color: "#039CFD" }}>BlogList</span>{" "}
              </Breadcrumbs>
            </Box>
            <MainHeading>BLOG LIST :-</MainHeading>
          </Box>
          <Box>
            <Link to="/blog-add" className="add-category-btn">
              <Button className="add-category-btn" variant="contained">Add Blog</Button>
            </Link>
          </Box>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">NUMBER</StyledTableCell>
                  <StyledTableCell align="center">Image</StyledTableCell>
                  <StyledTableCell align="center">Name </StyledTableCell>
                  <StyledTableCell align="center">Discription</StyledTableCell>
                  <StyledTableCell align="center">ACTION</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => {
                  const itemsPerPage = 8;
                  return (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row" align="center">
                        {(page - 1) * itemsPerPage + index + (page > 0 && 1)}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="center">
                        <LazyLoadImage
                          src={row?.image}
                          alt="img"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="center">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.description}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <ModeEditOutlineIcon
                          className='edit-icon'
                          onClick={() => navigate(`/blog-edit/${row.id}`)}
                        />
                        <DeleteIcon
                          className="delete-icon"
                          onClick={() => {
                            setShowModel(true);
                            setId(row?.id);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            padding: "10px",
          }}
        >
          <Pagination
            count={paginationBlog?.totalPages}
            onChange={(e) => {
              setPage(parseInt(e.target.innerText));
            }}
            page={page}
            color="primary"
          />
        </Box>
      </SubBox>
      {showModel && (
        <DeleteModel
          open={handleOpen}
          onClose={handleClose}
          id={id}
          mainTitle="Blog"
        />
      )}
    </MainBox>
  );
};

export default BlogList;
