import {
  Box,
  Button,
  InputBase,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { Form, Formik, FieldArray, Field } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useGetCategory } from "../../Hooks/Category";
import * as _ from "lodash";
import { useGetSubCategory } from "../../Hooks/SubCategory";
import { useAddBlog, useUpdateBlog, useGetSingleBlog } from "../../Hooks/Blog";
// import Textarea from '@mui/joy/Textarea';

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { useQueryClient } from "react-query";
import BlogImage from "./BlogImage";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Require from "../../Component/Require";
const SignupSchema = Yup.object().shape({
  category: Yup.string().required("Category name is Required"),
  subCategory: Yup.string().required("Sub Category name is Required"),
  title: Yup.string().required("title is Required"),
  blogReadTime: Yup.number().required("Blog Read Time name is Required").typeError('It must be a number'),
  metaTitle: Yup.string().required("Meta Title name is Required"),
  metaKeyword: Yup.string().required("Meta Keyword name is Required"),
  metaDescription: Yup.string().required("Meta Description name is Required"),
  uri: Yup.string().required("URI is Required"),
  shortDescription: Yup.string().required("Short Description name is Required"),
  cardContent: Yup.string().required("Card Content name is Required"),
  featured: Yup.string().required("Featured is Required"),
});

const BlogPage = () => {
  const { blogId } = useParams();

  const FieldTitle = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: 500,
    padding: "10px 0px",
    textAlign: "left",
  }));
  const Input = styled(InputBase)(() => ({
    backgroundColor: "none",
    paddingLeft: "15px",
    borderRadius: "8px",
    height: "60px",
    width: "100%",
    background: "#f0f0f1",
  }));
  const LoginButton = styled(Button)(() => ({
    height: 55,
    fontSize: 16,
    fontWeight: 500,
    background: "#1A202C",
    textTransform: "capitalize",
    color: "#FFFFFF",
    borderRadius: "8px",
    width: "100%",
    marginTop: "2% ",
    '&:hover': {
      backgroundColor: "#1A202C",
    }
  }));
  const MainHeading = styled(Typography)(() => ({
    fontSize: "25px",
    fontWeight: 400,
    padding: "30px 0px 10px 0px",
    textAlign: "left",
    color: '#1A202C'
  }));
  const MainBox = styled(Box)(() => ({
    backgroundColor: "#F8FAFB",
    padding: "15px",
  }));
  const SubBox = styled(Box)(() => ({
    background: "#E2E8F0",
    padding: "50px",
    borderRadius: "20px",
    border: '1px solid #d9d9d9',
    ".breadcrumbs-box": {
      display: "flex",
      alignItems: "center",
    },
    ".back-btn": {
      color: "#000",
      marginRight: "10px",
    },
  }));
  const Breadcrumbs = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: 300,
    textAlign: "left",
    width: "auto", color: '#1A202C'
  }));
  const ContainBox = styled(Box)(() => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px",
  }));
  const ImageContainBox = styled(Box)(() => ({
    width: "100%",
    display: "flex",
    padding: "10px 0px",
  }));
  const SelectBox = styled(Select)(() => ({
    width: "100%",
    boxShadow: "none",
    backgroundColor: "#f0f0f1",
    borderRadius: "8px",
    ".MuiOutlinedInput-notchedOutline": {
      border: 0,
      borderRadius: "12px",
    },
  }));
  const SectionBox = styled(Box)(() => ({
    border: "1px solid #a6a6a6",
    borderRadius: "15px",
    padding: "20px 20px",
    margin: "20px 0px",
  }));
  const SectionBtnBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    width: "18%",
  }));

  const { mutate: addblog } = useAddBlog();
  const { data: newCategory } = useGetCategory();
  const { data: newSubCategory } = useGetSubCategory();
  const { mutate: editBlog } = useUpdateBlog();
  const { data: singleBlog } = useGetSingleBlog(blogId, !!blogId);
  const queryClient = useQueryClient();

  const initialValue = (singleBlog) ? singleBlog : {
    category: "",
    subCategory: "",
    title: "",
    blogReadTime: "",
    metaTitle: "",
    thumbnailImage: "",
    metaKeyword: "",
    metaDescription: "",
    uri: "",
    shortDescription: "",
    cardContent: "",
    featured: "",
    section: [""],
    tableIndexSection: [{ title: "", section: [] }],
  }

  const [updatedInitialValues, setUpdatedInitialValues] = useState(initialValue);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const editSectionData = _.map(singleBlog?.section, ((data) => data?.image));

  const editTableImage = (_.map(singleBlog?.tableIndexSection, ((data) => data?.section))).map((data) => data[0]?.image);

  const editBlogImageData = singleBlog?.image;
  const editThumbnailImageData = singleBlog?.thumbnailImage;
  const finalSubCategory = newSubCategory?.data?.subCategory?.results;
  const newAddCategory = newCategory?.data?.category?.results;
  // const editHomeThumbnailData = singleBlog?.homeThumbnail;


  return (
    <MainBox>
      <SubBox>
        <Box className="breadcrumbs-box">
          <Link to="/blog-list">
            <ArrowBackOutlinedIcon className="back-btn" />
          </Link>
          <Breadcrumbs>
            Dashboard /{" "}
            {blogId ? (
              <span style={{ color: "#039CFD" }}>Edit Blog</span>
            ) : (
              <span style={{ color: "#039CFD" }}>Add Blog</span>
            )}
          </Breadcrumbs>
        </Box>
        {blogId ? (
          <MainHeading>EDIT BLOG :-</MainHeading>
        ) : (
          <MainHeading>ADD BLOG :-</MainHeading>
        )}
        <Formik
          // initialValues={{ ...initialValue }}
          initialValues={!isFormSubmitted ? initialValue : updatedInitialValues}
          validationSchema={SignupSchema}

          onSubmit={(values, { setSubmitting }) => {
            //TODO : here the get all image using query-key
            const thumbnailImageData = queryClient.getQueryData("thumbnailImage");
            const blogImageData = queryClient.getQueryData("image");
            // const homeThumbnailData = queryClient.getQueryData("homeThumbnail");
            const sectionImageData = queryClient.getQueryData("section-image");
            const tableIndexSection = queryClient.getQueryData("tableIndexSection-image")
            const uploadImage = queryClient.getQueryData("uploadImageLink");


            const updatedSection = values.section.map((data) => {
              delete data["_id"];
              return {
                ...data,
                image: sectionImageData?.location,
              };
            });

            // ! for add tableIndex section :
            const addTableSection = values.tableIndexSection.map((data) => {
              const newAddSection = data?.section?.map((section) => {
                delete section["_id"];
                return {
                  ...section,
                  image: tableIndexSection?.location
                }
              })
              delete data["_id"];
              return {
                ...data,
                section: newAddSection
              }
            })

            const newUpdatedSection = values.section?.map((data) => {
              delete data["_id"];
              return {
                ...data,
                image: sectionImageData?.location,
              };
            });

            const finalValues = {
              ...values,
              image: blogImageData?.location,
              thumbnailImage: thumbnailImageData?.location,
              // homeThumbnail: homeThumbnailData?.location,
              section: updatedSection,
              tableIndexSection: addTableSection
            };
            if (blogId) {
              values = {
                ...values,
                section: values.section.map((data) => {
                  delete data["_id"];
                  return {
                    ...data,
                    image: sectionImageData?.location || data.image,
                  };
                }),
              };
            }
            blogId ? editBlog({
              ...values,
              id: blogId,
              image: blogImageData?.location || singleBlog?.image,
              thumbnailImage: thumbnailImageData?.location,
              // homeThumbnail: homeThumbnailData?.location,
              // TODO: this it will use in future
              // section: newUpdatedSection,
              tableIndexSection: addTableSection
            }) : addblog(finalValues);
            setUpdatedInitialValues(values);
            setIsFormSubmitted(true);
          }}
          enableReinitialize={true}
        >
          {({
            errors,
            touched,
            handleSubmit,
            values,
            handleChange,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex" }}>
                <ContainBox>
                  <Box sx={{ width: "49%" }}>
                    <FieldTitle>Category :- <Require /></FieldTitle>
                    <SelectBox
                      name="category"
                      value={values.category}
                      label="category"
                      onChange={(e) =>
                        setFieldValue("category", e.target.value)
                      }
                    >
                      {_.map(newAddCategory, (data, index) => {
                        return (
                          <MenuItem key={index} value={data.id}>
                            {data?.name}
                          </MenuItem>
                        );
                      })}
                    </SelectBox>
                    {touched.category && errors.category && (
                      <Typography sx={{ color: "red", fontSize: "12px" }}>
                        {errors.category}
                      </Typography>
                    )}
                  </Box>

                  <Box sx={{ width: "49%" }}>
                    <FieldTitle>Sub Category :-<Require /></FieldTitle>
                    <SelectBox
                      value={values.subCategory}
                      label="Age"
                      onChange={(e) =>
                        setFieldValue("subCategory", e.target.value)
                      }
                    >
                      {_.map(finalSubCategory, (data, ind) => {
                        return (
                          <MenuItem key={ind} value={data.id}>
                            {data?.name}
                          </MenuItem>
                        );
                      })}
                    </SelectBox>
                    {touched.subCategory && errors.subCategory && (
                      <Typography sx={{ color: "red", fontSize: "12px" }}>
                        {errors.subCategory}
                      </Typography>
                    )}
                  </Box>
                </ContainBox>
              </Box>
              <ContainBox>
                <Box sx={{ width: "49%" }}>
                  <FieldTitle>Title :-<Require /></FieldTitle>
                  <Input
                    placeholder="Title Name"
                    fullWidth
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue(
                        "uri",
                        e.target.value.replace(
                          /[&/\\#,@ +()$~%.'":*?<>{}]/g,
                          "-"
                        ).toLowerCase()
                      );
                    }}
                    name="title"
                    value={values.title}
                  />
                  {touched.title && errors.title && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errors.title}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ width: "49%" }}>
                  <FieldTitle>Blog Read Time:-<Require /></FieldTitle>
                  <Input
                    placeholder="Enter Blog Read Time"
                    fullWidth
                    onChange={handleChange}
                    name="blogReadTime"
                    value={values.blogReadTime}
                  />
                  {touched.blogReadTime && errors.blogReadTime && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errors.blogReadTime}
                    </Typography>
                  )}
                </Box>
              </ContainBox>
              <ContainBox>
                <Box sx={{ width: "49%" }}>
                  <FieldTitle>Meta Title :-<Require /></FieldTitle>
                  <Input
                    placeholder="category name"
                    onChange={handleChange}
                    name="metaTitle"
                    value={values.metaTitle}
                  />
                  {touched.metaTitle && errors.metaTitle && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errors.metaTitle}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ width: "49%" }}>
                  <FieldTitle>Meta Keyword:-<Require /></FieldTitle>
                  <Input
                    placeholder="category name"
                    onChange={handleChange}
                    name="metaKeyword"
                    value={values.metaKeyword}
                  />
                  {touched.metaKeyword && errors.metaKeyword && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errors.metaKeyword}
                    </Typography>
                  )}
                </Box>
              </ContainBox>
              <ContainBox>
                <Box sx={{ width: "100%" }}>
                  <FieldTitle>Meta Description :-<Require /></FieldTitle>
                  <Field
                    style={{ width: '100%', border: 'none', background: '#f0f0f1', borderRadius: '8px' }}
                    onChnage={handleChange}
                    placeholder="Meta Description name"
                    name="metaDescription"
                    component="textarea"
                    value={values.metaDescription}
                    rows="4"
                  />
                  {touched.metaDescription && errors.metaDescription && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errors.metaDescription}
                    </Typography>
                  )}
                </Box>
              </ContainBox>
              <ContainBox>
                <Box sx={{ width: "49%" }}>
                  <FieldTitle>URI :-<Require /></FieldTitle>
                  <Input
                    placeholder="uri name"
                    onChange={handleChange}
                    name="uri"
                    value={values.uri}
                    disabled
                  />
                  {touched.uri && errors.uri && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errors.uri}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ width: "49%" }}>
                  <FieldTitle>Featured :-<Require /></FieldTitle>
                  <SelectBox
                    value={values.featured}
                    label="Featured"
                    onChange={(e) => setFieldValue("featured", e.target.value)}
                  >
                    <MenuItem value="true">true</MenuItem>
                    <MenuItem value="false">false</MenuItem>
                  </SelectBox>
                  {touched.featured && errors.featured && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errors.featured}
                    </Typography>
                  )}
                </Box>
              </ContainBox>

              <ImageContainBox>
                <BlogImage
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  title="Blog Image"
                  imageType={"image"}
                  name={"image"}
                  imageLink={editBlogImageData}
                  isRequired={true}
                />

                <Box>
                  <BlogImage
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    title="Thumbnail Image"
                    imageType={"thumbnailImage"}
                    name={"thumbnailImage"}
                    imageLink={editThumbnailImageData}
                    isRequired={true}
                  />
                </Box>

                {/* Commented homethumbnail */}
                {/* <BlogImage
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  title="Home Thumbnail"
                  imageType={"homeThumbnail"}
                  name={"homeThumbnail"}
                  imageLink={editHomeThumbnailData}
                  isRequired={true}
                /> */}
              </ImageContainBox>

              <ContainBox>

              </ContainBox>

              <ContainBox>
                <Box sx={{ width: "100%" }}>
                  <FieldTitle>Short Description :-<Require /></FieldTitle>
                  <Field
                    style={{ width: '100%', border: 'none', background: '#f0f0f1', borderRadius: '8px' }}
                    placeholder="Short Description"
                    onChange={handleChange}
                    name="shortDescription"
                    value={values.shortDescription}
                    component="textarea"
                    rows="4"
                  />
                  {touched.shortDescription && errors.shortDescription && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errors.shortDescription}
                    </Typography>
                  )}
                </Box>
              </ContainBox>
              <Box sx={{ width: "100%" }}>
                <FieldTitle>Card Content:-<Require /></FieldTitle>

                <CKEditor
                  name="cardContent"
                  editor={ClassicEditor}
                  config={{
                    fontFamily: {
                      options: [
                        "default",
                        "Ubuntu, Arial, sans-serif",
                        "Ubuntu Mono, Courier New, Courier, monospace",
                      ],
                    },
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                      "ckfinder",
                      "|",
                      "imageTextAlternative",
                      "imageUpload",
                      "imageStyle:full",
                      "imageStyle:side",
                      "|",
                      "mediaEmbed",
                      "insertTable",
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "|",
                      "undo",
                      "redo",
                    ],
                  }}
                  data={singleBlog?.cardContent ? singleBlog?.cardContent : ""}
                  onReady={(editor) => {
                    //! You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    setFieldValue("cardContent", editor.getData());
                  }}
                  onError={(error) => { }}
                />
              </Box>
              {touched.cardContent && errors.cardContent && (
                <Typography sx={{ color: "red", fontSize: "12px" }}>
                  {errors.cardContent}
                </Typography>
              )}

              <Box>
                <FieldArray name="section">
                  {({ push, remove }) => (
                    <Box>
                      {values?.section?.map((p, index) => {
                        return (
                          <Box key={index}>
                            <SectionBox>
                              <Typography sx={{ fontSize: "20px" }}>
                                Section {index + 1} :-
                              </Typography>
                              <ContainBox>
                                <Box sx={{ width: "49%" }}>
                                  <BlogImage
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    title={`Section Image ${index + 1}`}
                                    imageType={"section-image"}
                                    name={"section-image"}
                                    imageLink={editSectionData}
                                  />
                                </Box>
                                <Box sx={{ width: "49%" }}>
                                  <FieldTitle>Video:-</FieldTitle>
                                  <Input
                                    placeholder="video"
                                    onChange={handleChange}
                                    name={`section[${index}].video`}
                                    value={values.section[index].video}
                                    type="text"
                                    accept="video/*"
                                  />
                                </Box>
                              </ContainBox>

                              <Box>
                                <FieldTitle>Video Caption:-</FieldTitle>
                                <Input
                                  placeholder="Card Content name"
                                  onChange={handleChange}
                                  name={`section[${index}].videoCaption`}
                                  value={values.section[index].videoCaption}
                                />
                              </Box>
                              <Box>
                                <FieldTitle>Content:-</FieldTitle>
                                <CKEditor
                                  name={`section[${index}].content`}
                                  editor={ClassicEditor}
                                  data={
                                    singleBlog?.section[index]?.content
                                      ? singleBlog?.section[index]?.content
                                      : " "
                                  }
                                  onReady={(editor) => {
                                    //!  You can store the "editor" and use when it is needed.
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue(
                                      `section[${index}].content`,
                                      data
                                    );
                                  }}
                                  onBlur={(event, editor) => { }}
                                  onFocus={(event, editor) => { }}
                                />
                              </Box>
                            </SectionBox>
                          </Box>
                        );
                      })}

                      <SectionBtnBox
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "18%",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => {
                            if (values.section.length) {
                              push({
                                image: "",
                                video: "",
                                videoCaption: "",
                                content: " ",
                              });
                            }
                          }}
                        >
                          Add Section
                        </Button>
                        <Button
                          variant="contained"
                          className="yellow-bg ml-20"
                          onClick={() => {
                            remove();
                          }}
                        >
                          Remove
                        </Button>
                      </SectionBtnBox>
                    </Box>
                  )}
                </FieldArray>
              </Box>

              <Box>
                <FieldArray name="tableIndexSection">
                  {({ push, remove }) => (
                    <Box>
                      {values?.tableIndexSection?.map((p, parentIndex) => {
                        return (
                          <Box key={parentIndex}>
                            <SectionBox>
                              <Typography sx={{ fontSize: "20px" }}>
                                Table Index Section {parentIndex + 1}
                              </Typography>
                              <ContainBox>
                                <Box sx={{ width: "100%" }}>
                                  <FieldTitle> Title :-</FieldTitle>
                                  <Input
                                    type="text"
                                    placeholder="Please Enter a Title"
                                    onChange={handleChange}
                                    name={`tableIndexSection[${parentIndex}].title`}
                                    value={values.tableIndexSection[parentIndex].title}
                                  />
                                </Box>
                              </ContainBox>

                              <Box>
                                <FieldArray name={`tableIndexSection[${parentIndex}].section`}>
                                  {({ push, remove }) => (
                                    <Box>
                                      {values?.tableIndexSection[parentIndex]?.section?.map((p, index) => {
                                        return (
                                          <Box key={index}>
                                            <SectionBox>
                                              <Typography sx={{ fontSize: "20px" }}>
                                                {index + 1} :-
                                              </Typography>
                                              <ContainBox>
                                                <Box sx={{ width: "49%" }}>
                                                  <BlogImage
                                                    setFieldValue={setFieldValue}
                                                    handleChange={handleChange}
                                                    title={`tableIndexSection Image ${index}`}
                                                    imageType={"tableIndexSection-image"}
                                                    name={"tableIndexSection-image"}
                                                    // imageLink={`section-image${index}`}
                                                    imageLink={editTableImage}
                                                  />
                                                </Box>
                                                <Box sx={{ width: "49%" }}>
                                                  <FieldTitle>Video:-</FieldTitle>
                                                  <Input
                                                    placeholder="video"
                                                    onChange={handleChange}
                                                    name={`tableIndexSection[${parentIndex}].section[${index}].video`}
                                                    value={values.tableIndexSection[parentIndex].section[index].video}
                                                    type="text"
                                                    accept="video/*"
                                                  />
                                                </Box>
                                              </ContainBox>

                                              <Box>
                                                <FieldTitle>Video Caption:-</FieldTitle>
                                                <Input
                                                  placeholder="Card Content name"
                                                  onChange={handleChange}
                                                  name={`tableIndexSection[${parentIndex}].section[${index}].videoCaption`}
                                                  value={values.tableIndexSection[parentIndex].section[index].videoCaption}
                                                />
                                              </Box>
                                              <Box>
                                                <FieldTitle>Content:-</FieldTitle>
                                                <CKEditor
                                                  name={`tableIndexSection[${parentIndex}].section[${index}].content`}
                                                  editor={ClassicEditor}
                                                  data={
                                                    singleBlog?.tableIndexSection[parentIndex]?.section[index]?.content
                                                      ? singleBlog?.tableIndexSection[parentIndex]?.section[index]?.content
                                                      : " "
                                                  }
                                                  onReady={(editor) => {
                                                    //!  You can store the "editor" and use when it is needed.
                                                  }}
                                                  onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue(
                                                      `tableIndexSection[${parentIndex}].section[${index}].content`,
                                                      data
                                                    );
                                                  }}
                                                  onBlur={(event, editor) => { }}
                                                  onFocus={(event, editor) => { }}
                                                />
                                              </Box>
                                            </SectionBox>
                                          </Box>
                                        );
                                      })}

                                      <SectionBtnBox
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "18%",
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={() => {
                                            if (values.tableIndexSection.length) {
                                              push({
                                                image: "",
                                                video: "",
                                                videoCaption: "",
                                                content: " ",
                                              });
                                            }
                                          }}
                                        >
                                          {" "}
                                          Add Section
                                        </Button>
                                        <Button
                                          variant="contained"
                                          className="yellow-bg ml-20"
                                          onClick={() => {
                                            remove();
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      </SectionBtnBox>
                                    </Box>
                                  )}
                                </FieldArray>
                              </Box>

                            </SectionBox>


                          </Box>
                        );
                      })}

                      <SectionBtnBox
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "18%",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => {
                            if (values.tableIndexSection.length) {
                              push({
                                title: "",
                              });
                            }
                          }}
                        >
                          {" "}
                          Add Section
                        </Button>
                        <Button
                          variant="contained"
                          className="yellow-bg ml-20"
                          onClick={() => {
                            remove();
                          }}
                        >
                          Remove
                        </Button>
                      </SectionBtnBox>
                    </Box>
                  )}
                </FieldArray>
              </Box>


              <Box sx={{ padding: "12px 0px" }}>
                {blogId ? (
                  <LoginButton variant="contained" type="submit">
                    Save Blog
                  </LoginButton>
                ) : (
                  <LoginButton variant="contained" type="submit">
                    Add Blog
                  </LoginButton>
                )}
              </Box>
            </Form>
          )
          }
        </Formik >
      </SubBox >
    </MainBox >
  );
};

export default BlogPage;

