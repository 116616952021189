import { deleteRequest, get, patch, post } from "../Utils/index";

// export const getSingleCategory = async (data) => {
//   const id = data?.queryKey[1];
//   return get(`/customer/user/${id}`);
// };

export const addCategory = async (data) => {
  return post(`/admin/category`, data);
};
export const getCategory = async () => {
  return get(`/admin/category`);
};
export const updateCategory = async (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/category/${id}`, data);
};
export const deleteCategory = async (id) => {
  return deleteRequest(`/admin/category/${id}`);
};

export const categoryApi = {
  getCategory,
  addCategory,
  updateCategory,
  deleteCategory,
};
