import React, { useRef, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useUploadFile } from "../../Hooks/uploadFile";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Require from "../../Component/Require";

const BlogImage = (props) => {
  const { blogId } = useParams();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    name,
    imageType,
    imageLink,
    title = "Image",
    setFieldValue,
    handleChange,
    isRequired = false,
  } = props;

  const FieldTitle = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: 500,
    padding: "10px 0px",
    textAlign: "left",
  }));

  const ErrorMessage = styled(Typography)({
    color: 'red',
    fontSize: "14px",
  })

  const imageRef = useRef();

  const { mutateAsync: uploadFiles, data } = useUploadFile();
  const queryClient = useQueryClient();
  const location = data?.data?.data;
  if (location) {
    queryClient.setQueryData(imageType, location?.[0]);
  }
  const currentImg = queryClient.getQueryData(imageType)?.location;
  const imageUrl = blogId ? imageLink : location?.[0]?.location;

  let imagesWithType = {
    image: {
      width: 1360,
      height: 600
    },
    thumbnailImage: {
      width: 650,
      height: 300
    },
    // homeThumbnail: {
    //   width: 300,
    //   height: 300
    // },

  }


  const handleBlogImage = async (e) => {
    setIsError(false);
    const fileData = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileData);
    formData.append("fileName", fileData.name);
    let img = new Image()
    img.src = window.URL.createObjectURL(e.target.files[0])
    img.onload = () => {
      if (imageType !== "section-image" && imageType !== "tableIndexSection-image" && imagesWithType[imageType].width === img.width && imagesWithType[imageType].height === img.height) {
        return uploadFiles(formData);
      } else if (imageType === 'section-image' || imageType === 'tableIndexSection-image') {
        return uploadFiles(formData);
      }
      else {
        setIsError(true);

        if (imageType === 'image') {
          setErrorMessage(`size must be 1360px x 600px`);
        } else if (imageType === 'thumbnailImage') {
          setErrorMessage(`size must be 650px x 300px`);
        } 
        // else if (imageType === 'homeThumbnail') {
        //   setErrorMessage(`size must be 300px x 300px`);
        // }
        return false;
      }
    }
  };

  return (
    <React.Fragment>
      <Box>
        <FieldTitle> {title} {isRequired && <Require />}</FieldTitle>
        <input type="hidden"
          onChange={(e) => {
            handleChange(e);
            setFieldValue(`${name} `, (imageUrl) ? (currentImg) ? currentImg : imageUrl : 'https://cdn-icons-png.flaticon.com/512/2748/2748558.png')
          }}
          name={name} value={imageUrl ? (currentImg ? currentImg : imageUrl) : 'https://cdn-icons-png.flaticon.com/512/2748/2748558.png'} />
        <input
          ref={imageRef}
          placeholder="Thumbnail Image"
          onChange={(e) => {
            handleBlogImage(e, name);
          }}
          name={`${name} _temp`}
          type="file"
        />
        {isError && <ErrorMessage> {errorMessage} </ErrorMessage>}
        <Box>
          {imageType === "image" && (
            <LazyLoadImage
              src={imageUrl ? (currentImg ? currentImg : imageUrl) : 'https://cdn-icons-png.flaticon.com/512/2748/2748558.png'}
              alt="testImage"
              width="180px"
              style={{ marginTop: "10px" }}
            />
          )}

          {/* {imageType === "homeThumbnail" && (
            <LazyLoadImage
              src={
                imageUrl
                  ? (currentImg
                    ? currentImg
                    : imageUrl)
                  : "https://cdn-icons-png.flaticon.com/512/2748/2748558.png"
              }
              alt="testimage"
              width="180px"
              style={{ marginTop: "10px" }}
            />
          )} */}
          {imageType === "thumbnailImage" && (
            <LazyLoadImage
              src={
                imageUrl
                  ? (currentImg
                    ? currentImg
                    : imageUrl)
                  : "https://cdn-icons-png.flaticon.com/512/2748/2748558.png"
              }
              alt="testimage"
              width="180px"
              style={{ marginTop: "10px" }}
            />
          )}

          {imageType === "section-image" && (
            <LazyLoadImage
              src={
                imageUrl
                  ? (currentImg
                    ? currentImg
                    : imageUrl)
                  : "https://cdn-icons-png.flaticon.com/512/2748/2748558.png"
              }
              alt="testmage"
              width="180px"
              style={{ marginTop: "10px" }}
            />
          )}
          {imageType === "tableIndexSection-image" && (
            <LazyLoadImage
              src={
                imageUrl
                  ? (currentImg
                    ? currentImg
                    : imageUrl)
                  : "https://cdn-icons-png.flaticon.com/512/2748/2748558.png"
              }
              alt="tableIndexSection-image"
              width="180px"
              style={{ marginTop: "10px" }}
            />
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default BlogImage;
