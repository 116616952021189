import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import AddCategory from './pages/dashboard/AddCategory';
import CategoryList from './pages/dashboard/CategoryList';
import AddSubCategory from './pages/dashboard/AddSubCategory';
import SubCategoryList from './pages/dashboard/SubCategoryList';
import { ToastContainer } from 'react-toastify';
import { cookie } from './Utils/Cookies/cookie';
import Dashboard from './pages/dashboard/Dashboard';
import ForgetPassword from './pages/auth/ForgotPassword';
import BlogPage from './pages/dashboard/BlogPage';
import BlogList from './pages/dashboard/BlogList';

const Login = lazy(() => import('./pages/auth/Login'));
const CommanComponent = lazy(() => import('./Component/CommanComponent'));
function App() {

  const isAuth = cookie.get('token');

  return (
    <div className="App">
      <Router>
        <Suspense fallback={<h1>Loading...</h1>}>
          <Routes>
            <Route path='/login' element={isAuth ? <Navigate to='/dashboard' /> : <Login />} />
            <Route path='/1' element={<ForgetPassword />} />

            <Route path='/' element={<CommanComponent />}>
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='add-category' element={<AddCategory />} />
              <Route path='edit-category/:id' element={<AddCategory />} />
              <Route path='category-list' element={<CategoryList />} />
              <Route path='add-sub-category' element={<AddSubCategory />} />
              <Route path='add-sub-category/:id' element={<AddSubCategory />} />
              <Route path='sub-category-list' element={<SubCategoryList />} />  
              <Route path='blog-add' element={<BlogPage />} />
              <Route path='blog-list' element={<BlogList />} />
              <Route path='blog-edit/:blogId' element={<BlogPage />} />
            </Route>
          </Routes>
          <ToastContainer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
