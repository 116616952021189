import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { categoryApi } from "../api/Category";

export const useAddCategory = () => {
  const navigate = useNavigate();
  return useMutation(categoryApi.addCategory, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);

      navigate("/category-list");
    },
    onError: (res) => {
      toast.error("addCategory errors");
    },
  });
};

export const useGetCategory = (isEnabled) => {
  return useQuery("test2", categoryApi.getCategory, {
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    // enabled:''
    onSuccess: (res) => {
      // toast.success("getCategory success");
    },
    onError: (res) => {
      // toast.error("getCategory error");
    },
  });
};

export const useUpdateCategory = () => {
  const navigation = useNavigate();
  return useMutation(categoryApi.updateCategory, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      navigation(`/category-list`);
    },
    onError: (res) => {
      toast.error("addCategory error");
    },
  });
};
export const useDeleteCategory = () => {
  const navigation = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(categoryApi.deleteCategory, {
    onSuccess: (res) => {
      toast.success(res?.data?.msg);
      queryClient.invalidateQueries("test2");
      navigation("/category-list");
    },
    onError: (res) => {
      toast.error("deleteCategory error");
    },
  });
};
