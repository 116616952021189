import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiBlog } from "../api/Blog";
export const useAddBlog = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(apiBlog.addBlog, {
    onSuccess: (res) => {
      navigate("/blog-list");
      toast.success(res?.data?.message);
      queryClient.removeQuery("image");
      queryClient.removeQuery("section-image");
      queryClient.removeQuery("thumbnailImage");
    },
    refetchOnWindowFocus: false,

    onError: (error) => { 
      toast.error(error?.data?.message);
    },
  });
};
export const useGetBlog = (page) => {
  return useQuery(["blog", page], apiBlog.getBlog, {
    keepPreviousData: true,
    // enabled: isEnabled,
    refetchOnWindowFocus: false,
    onSuccess: (data) => { },
    onError: (error) => { },
  });
};

export const useUpdateBlog = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(apiBlog.updateBlog, {
    onSuccess: (res) => {
      navigate("/blog-list");
      toast.success(res?.data?.message);
      queryClient.removeQuery("image");
      queryClient.removeQuery("section-image");
      queryClient.removeQuery("thumbnailImage");
    },
    onError: (error) => { },
  });
};

export const useDeleteBlog = () => {
  const navigation = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(apiBlog.deleteBlog, {
    onSuccess: (res) => {
      toast.success(res?.data?.msg);
      queryClient.invalidateQueries("blog");
      navigation("/blog-list");
    },
    onError: (error) => { },
  });
};

export const useSingleBlog = () => {
  const queryClient = useQueryClient();
  return useMutation(apiBlog.getSingleBlog, {
    onSuccess: (res) => {
      queryClient.setQueryData("singleBlog", res.data.singleBlog);
    },
    onError: (error) => { },
  });
};

export const useGetSingleBlog = (id, isEnabled = true) => {
  const queryClient = useQueryClient();
  return useQuery(["singleBlog", id], apiBlog.getSingleBlog, {
    select: (res) => res.data.singleBlog,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    onSuccess: (res) => {
      console.log('res===>', res);
    },
    onError: (error) => { },
  });
};
