import {
  Box,
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useGetSubCategory } from "../../Hooks/SubCategory";
import { Link, useNavigate } from "react-router-dom";
import DeleteModel from "../DeleteModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFB",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SubCategoryList = () => {
  const navigate = useNavigate();

  const { data: getAllSubCategory } = useGetSubCategory();
  const subCategory = getAllSubCategory?.data?.subCategory?.results;
  const rows = subCategory?.map((data) => {
    return {
      name: data?.name,
      id: data?.id,
    };
  });

  //! for deleteBlog modal
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState("");
  const handleClose = () => {
    setShowModel(false);
  };
  const handleOpen = () => {
    setShowModel(true);
  };

  const BoxWrapper = styled(Box)(() => ({
    backgroundColor: "#F8FAFB",
    height: "79vh",
    padding: "15px",
    borderTop: "2px solid #E6EAEF",
    '.subCategoryListBox': {
      display: "flex",
      justifyContent: "space-between"
    },
    '.subBox': {
      background: "#fff",
      padding: "50px",
      borderRadius: "20px",
      height: "70vh",
    },
    ".add-category-btn": {
      textDecoration: "none",
    },
    '.add-sub-category-btn': {
      backgroundColor: "#1A202C",
    },
    '.breadcrumbs': {
      fontSize: "12px",
      fontWeight: 300,
      textAlign: "left",
      width: "auto",
    },
    '.mainHeading': {
      fontSize: "25px",
      fontWeight: 400,
      padding: "10px 0px",
      textAlign: "left",
      paddingBottom: "30px",
    },
    '.subTable': {
      minWidth: 700
    },
    '.subCategoryTitle': {
      color: "#039CFD"
    },
    '.editIcon': {
      color: "#1565c0"
    }
  }));
  return (
    <BoxWrapper>
      <Box className='subBox'>
        <Box className='subCategoryListBox' >
          <Box>
            <Typography className="breadcrumbs">
              Dashboard /
              <span className='subCategoryTitle'>Sub Category List</span>
            </Typography>
            <Typography className="mainHeading">SUB CATEGORY LIST :-</Typography>
          </Box>
          <Link to="/add-sub-category" className="add-category-btn">
            <Button className="add-sub-category-btn" variant="contained">Add sub Category </Button>
          </Link>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table className="subTable" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">NUMBER</StyledTableCell>
                  <StyledTableCell align="center">
                    SUB CATEGORY NAME
                  </StyledTableCell>
                  <StyledTableCell align="center">ACTION</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      <ModeEditOutlineIcon
                        onClick={() => {
                          navigate(`/add-sub-category/${row.id}`);
                        }}
                        className='editIcon'
                      />
                      <DeleteIcon
                        onClick={() => {
                          setShowModel(true);
                          setId(row?.id);
                        }}
                      />{" "}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {showModel && (
        <DeleteModel
          open={handleOpen}
          onClose={handleClose}
          id={id}
          mainTitle="Sub Category"
        />
      )}
    </BoxWrapper>
  );
};

export default SubCategoryList;
