import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { uplodeFileApi } from "../api/uploadFile";

export const useUploadFile = (objectData) => {
  const queryClient = useQueryClient();
  return useMutation(uplodeFileApi.uplodeFile, {
    select: (data) => data?.data?.data,
    onSuccess: (res) => {
      toast.success("Image uploaded success");
      queryClient.setQueryData("uploadImageLink", res.data?.data[0].docLink);
    },
    onError: (error) => { },
  });
};
