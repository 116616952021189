import { get, post } from "../Utils/index";

export const login = async (data) => {
  return post(`/admin/auth/login`, data);
};

export const forgotPassword = (data) => {
  return post(`/auth/forgot-password`, data);
};

export const changePassword = (data) => {
  return post(`/profile/change-password`, data);
};

export const getTokon = (data) => {
  return get(`/admin/profile/me`);
};
export const authApi = {
  login,
  forgotPassword,
  changePassword,
  getTokon,
};
