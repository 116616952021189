import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { authApi } from "../api/auth";
import { cookie } from "../Utils/Cookies/cookie";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useLogin = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(authApi.login, {
    onSuccess: (res) => {
      const data = res.data;
      if (data.tokens) {
        queryClient.setQueryData(["user"], data.user);
        cookie.set("token", data?.tokens?.access?.token);
        navigate("/dashboard");
        toast.success("Login Success");
      }
    },
    onError: (err, prev, context) => {
      const errData = err?.data;
      queryClient.invalidateQueries(["user"]);
      navigate("/login");
      toast.error(errData.message);
    },
  });
};

export const useForgetPassword = () => {
  return useMutation(authApi.forgotPassword, {
    onSuccess: (err, prev, context) => {
      toast.success("forget password Success");
    },
    onError: (err, prev, context) => {
      toast.success("forget password Success");
    },
  });
};

export const useVerifyToken = (isDisable) => {
  return useQuery([`test-data`], authApi.getTokon, {
    enabled: isDisable,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      // toast.success("token successfully");
    },
    onError: (err) => {
      cookie.remove("token");
      toast.error(err.message);
      // navigate('/login');
    },
  });
};
